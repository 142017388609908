import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_STRIPE_REQUEST = "GET_STRIPE_REQUEST";
export const GET_STRIPE_SUCCESS = "GET_STRIPE_SUCCESS";
export const GET_STRIPE_FAILURE = "GET_STRIPE_FAILURE";

export const UPDATE_STRIPE_REQUEST = "UPDATE_STRIPE_REQUEST";
export const UPDATE_STRIPE_SUCCESS = "UPDATE_STRIPE_SUCCESS";
export const UPDATE_STRIPE_FAILURE = "UPDATE_STRIPE_FAILURE";

export const getStripeKeys = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STRIPE_REQUEST });
      const url = `${BASE_URL}/stripe/get/keys/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_STRIPE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_STRIPE_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const updateStripe = (stripeId, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_STRIPE_REQUEST });
      const url = `${BASE_URL}/stripe//update/${stripeId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_STRIPE_SUCCESS, payload: response.data });
      toast.success("Updated Successfully");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_STRIPE_FAILURE, payload: error.message });
    }
  };
};
