import * as degreeAction from "./degreeAction";

export const degreeFeatureKey = "degreeInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  degreesList: [],
  degreesFromUniversityList: [],
  degreeDetails: {},
  isDegreeUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case degreeAction.GET_DEGREE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case degreeAction.GET_DEGREE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        degreeDetails: payload.data,
      };
    case degreeAction.GET_DEGREE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        degreeDetails: [],
      };
    case degreeAction.GET_DEGREES_FROM_UNIVERSITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case degreeAction.GET_DEGREES_FROM_UNIVERSITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        degreesFromUniversityList: payload.data
      };
    case degreeAction.GET_DEGREES_FROM_UNIVERSITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        degreesFromUniversityList: []
      };
    case degreeAction.GET_DEGREE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case degreeAction.GET_DEGREE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        degreesList: payload.data
      };
    case degreeAction.GET_DEGREE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case degreeAction.CREATE_DEGREE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case degreeAction.CREATE_DEGREE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDegreeUpdated: true,
      };
    case degreeAction.CREATE_DEGREE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case degreeAction.UPDATE_DEGREE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case degreeAction.UPDATE_DEGREE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDegreeUpdated: true,
      };
    case degreeAction.UPDATE_DEGREE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case degreeAction.DELETE_DEGREE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case degreeAction.DELETE_DEGREE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDegreeUpdated: true,
      };
    case degreeAction.DELETE_DEGREE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_DEGREE_UPDATED":
      return {
        ...state,
        isDegreeUpdated: false,
      };
    default:
      return state;
  }
};
