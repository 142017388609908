import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_DEGREE_DETAILS_REQUEST = "GET_DEGREE_DETAILS_REQUEST";
export const GET_DEGREE_DETAILS_SUCCESS = "GET_DEGREE_DETAILS_SUCCESS";
export const GET_DEGREE_DETAILS_FAILURE = "GET_DEGREE_DETAILS_FAILURE";

export const GET_DEGREE_LIST_REQUEST = "GET_DEGREE_LIST_REQUEST";
export const GET_DEGREE_LIST_SUCCESS = "GET_DEGREE_LIST_SUCCESS";
export const GET_DEGREE_LIST_FAILURE = "GET_DEGREE_LIST_FAILURE";

export const GET_DEGREES_FROM_UNIVERSITY_LIST_REQUEST = "GET_DEGREES_FROM_UNIVERSITY_LIST_REQUEST";
export const GET_DEGREES_FROM_UNIVERSITY_LIST_SUCCESS = "GET_DEGREES_FROM_UNIVERSITY_LIST_SUCCESS";
export const GET_DEGREES_FROM_UNIVERSITY_LIST_FAILURE = "GET_DEGREES_FROM_UNIVERSITY_LIST_FAILURE";

export const CREATE_DEGREE_REQUEST = "CREATE_DEGREE_REQUEST";
export const CREATE_DEGREE_SUCCESS = "CREATE_DEGREE_SUCCESS";
export const CREATE_DEGREE_FAILURE = "CREATE_DEGREE_FAILURE";

export const UPDATE_DEGREE_REQUEST = "UPDATE_DEGREE_REQUEST";
export const UPDATE_DEGREE_SUCCESS = "UPDATE_DEGREE_SUCCESS";
export const UPDATE_DEGREE_FAILURE = "UPDATE_DEGREE_FAILURE";

export const DELETE_DEGREE_REQUEST = "DELETE_DEGREE_REQUEST";
export const DELETE_DEGREE_SUCCESS = "DELETE_DEGREE_SUCCESS";
export const DELETE_DEGREE_FAILURE = "DELETE_DEGREE_FAILURE";

export const getDegreeList = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEGREE_LIST_REQUEST });
      const url = `${BASE_URL}/degree/admin/list/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_DEGREE_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_DEGREE_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getDegreeDetails = (degreeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEGREE_DETAILS_REQUEST });
      const url = `${BASE_URL}/degree/admin/${degreeId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_DEGREE_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_DEGREE_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const getDegreeByUniversity = (universityId, searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DEGREES_FROM_UNIVERSITY_LIST_REQUEST });
      const url = `${BASE_URL}/degree/admin/list/university/${universityId}/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_DEGREES_FROM_UNIVERSITY_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_DEGREES_FROM_UNIVERSITY_LIST_FAILURE, payload: error.message });
    }
  };
};

export const createDegree = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_DEGREE_REQUEST });
      const url = `${BASE_URL}/degree/admin/create/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_DEGREE_REQUEST, payload: response.data });
      toast.success("Degree Created Successfully");
      navigate("/degree/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_DEGREE_REQUEST, payload: error.message });
    }
  };
};

export const updateDegree = (degreeId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_DEGREE_REQUEST });
      const url = `${BASE_URL}/degree/admin/update/${degreeId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_DEGREE_SUCCESS, payload: response.data });
      toast.success("Degree Updated Successfully");
      navigate("/degree/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_DEGREE_FAILURE, payload: error.message });
    }
  };
};

export const deleteDegree = (degreeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_DEGREE_UPDATED" });
      dispatch({ type: DELETE_DEGREE_REQUEST });
      const url = `${BASE_URL}/degree/admin/delete/${degreeId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_DEGREE_SUCCESS, payload: response.data });
      toast.success("Degree Deleted Successfully");
      try {
        dispatch({ type: GET_DEGREE_LIST_REQUEST });
        const url = `${BASE_URL}/degree/admin/list/v1`;
        const response = await axios.get(url, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        dispatch({ type: GET_DEGREE_LIST_SUCCESS, payload: response.data });
      } catch (error) {
        console.error(error);
        dispatch({ type: GET_DEGREE_LIST_FAILURE, payload: error.message });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_DEGREE_FAILURE, payload: error.message });
    }
  };
};
