import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';

const CustomModal = ({
  confirmOnClick,
  btnClass,
  modalBodyContent,
  withButton = false,
  primaryBtnText = false,
  secondaryBtnText = false,
}) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      {withButton ? (
        <button
          onClick={() => setModalShow(true)}
          className="btn btn-light bg-white btn-fw d-flex align-items-center"
        >
          {" "}
          <i className={`mdi ${btnClass} menu-icon mr-1`}></i> {withButton}{" "}
        </button>
      ) : (
        <i
          type="button"
          onClick={() => setModalShow(true)}
          className={`mdi ${btnClass} menu-icon p-1`}
        ></i>
      )}

      <Modal
        show={modalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h4>{modalBodyContent}</h4>
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark btn-fw"
            onClick={() => setModalShow(false)}
          >
            {secondaryBtnText ? secondaryBtnText : "Not now"}
          </button>
          <button onClick={confirmOnClick} type="button" className="btn btn-primary btn-fw">
            {primaryBtnText ? primaryBtnText : "Confirm"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
