import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_BLOGS_REQUEST = "GET_BLOGS_REQUEST";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";
export const GET_BLOGS_FAILURE = "GET_BLOGS_FAILURE";

export const GET_BLOGS_DETAILS_REQUEST = "GET_BLOGS_DETAILS_REQUEST";
export const GET_BLOGS_DETAILS_SUCCESS = "GET_BLOGS_DETAILS_SUCCESS";
export const GET_BLOGS_DETAILS_FAILURE = "GET_BLOGS_DETAILS_FAILURE";

export const CREATE_BLOGS_REQUEST = "CREATE_BLOGS_REQUEST";
export const CREATE_BLOGS_SUCCESS = "CREATE_BLOGS_SUCCESS";
export const CREATE_BLOGS_FAILURE = "CREATE_BLOGS_FAILURE";

export const UPDATE_BLOGS_REQUEST = "UPDATE_BLOGS_REQUEST";
export const UPDATE_BLOGS_SUCCESS = "UPDATE_BLOGS_SUCCESS";
export const UPDATE_BLOGS_FAILURE = "UPDATE_BLOGS_FAILURE";

export const DELETE_BLOGS_REQUEST = "DELETE_BLOGS_REQUEST";
export const DELETE_BLOGS_SUCCESS = "DELETE_BLOGS_SUCCESS";
export const DELETE_BLOGS_FAILURE = "DELETE_BLOGS_FAILURE";

export const getBlogs = (searchQuery, startDate, endDate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BLOGS_REQUEST });
      let url;
      if (startDate && endDate) {
        url = `${BASE_URL}/blog/get/blogs/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}&startDate=${startDate}&endDate=${endDate}`;
      } else {
        url = `${BASE_URL}/blog/get/blogs/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      }
      const response = await axios.get(url);
      dispatch({ type: GET_BLOGS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_BLOGS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getBlogsDetails = (_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BLOGS_DETAILS_REQUEST });
      const url = `${BASE_URL}/blog/get/blog/details/${_id}/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_BLOGS_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_BLOGS_DETAILS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const createBlogs = (data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_BLOGS_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/blog/admin/upload/blog/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.blogImage = imgArr[0];
      }
      const url = `${BASE_URL}/blog/admin/create/blogs/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Successfully created");
      dispatch({ type: CREATE_BLOGS_SUCCESS, payload: response.data });
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_BLOGS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBlog = (_id, data, file, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BLOGS_REQUEST });
      if (file.length > 0) {
        const imgArr = [];
        const uploadPromises = file.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/blog/admin/upload/blog/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.blogImage = imgArr[0];
      }
      const url = `${BASE_URL}/blog/admin/update/blog/${_id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_BLOGS_SUCCESS, payload: response.data });
      toast.success("Blog Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_BLOGS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const deleteBlogs = (_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_BLOG_UPDATED" });
      dispatch({ type: DELETE_BLOGS_REQUEST });
      const url = `${BASE_URL}/blog/admin/delete/blog/${_id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_BLOGS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: DELETE_BLOGS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
