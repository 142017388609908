import * as stripeAction from "./stripeAction";

export const stripeFeatureKey = "stripeInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  stripe: {},
  isStripeUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case stripeAction.GET_STRIPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case stripeAction.GET_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
        stripe: payload?.data,
      };
    case stripeAction.GET_STRIPE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case stripeAction.UPDATE_STRIPE_REQUEST:
      return {
        ...state,
        loading: true,
        isStripeUpdated: false,
      };
    case stripeAction.UPDATE_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStripeUpdated: true,
      };
    case stripeAction.UPDATE_STRIPE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
