import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const getDashboardDetails = () => {
    return async (dispatch) => {
      try {
        dispatch({ type: GET_DASHBOARD_REQUEST });
        const url = `${BASE_URL}/dashboard/complete/details/v1`;
        const response = await axios.get(url, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        dispatch({ type: GET_DASHBOARD_SUCCESS, payload: response.data });
      } catch (error) {
        console.error(error);
        dispatch({ type: GET_DASHBOARD_FAILURE, payload: error.message });
      }
    };
  };