import * as planAction from "./yearAction";

export const yearFeatureKey = "yearInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  years: [],
  yearDetails: {},
  universityYears: [],
  isYearUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case planAction.GET_UNIVERSITY_YEAR_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.GET_UNIVERSITY_YEAR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        yearDetails: payload.data,
      };
    case planAction.GET_UNIVERSITY_YEAR_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        yearDetails: [],
      };
    case planAction.GET_UNIVERSITY_YEAR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.GET_UNIVERSITY_YEAR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        years: payload.data
      };
    case planAction.GET_UNIVERSITY_YEAR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        years: []
      };
    case planAction.GET_UNIVERSITY_YEARS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.GET_UNIVERSITY_YEARS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        universityYears: payload.data
      };
    case planAction.GET_UNIVERSITY_YEARS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        universityYears: [],
        errorMsg: payload,
      };
    case planAction.CREATE_UNIVERSITY_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.CREATE_UNIVERSITY_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isYearUpdated: true,
      };
    case planAction.CREATE_UNIVERSITY_YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case planAction.UPDATE_UNIVERSITY_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.UPDATE_UNIVERSITY_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isYearUpdated: true,
      };
    case planAction.UPDATE_UNIVERSITY_YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case planAction.DELETE_UNIVERSITY_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.DELETE_UNIVERSITY_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isYearUpdated: true,
      };
    case planAction.DELETE_UNIVERSITY_YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_YEAR_UPDATED":
      return {
        ...state,
        isYearUpdated: false,
      };
    default:
      return state;
  }
};
