import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_FOR_ADMIN_REQUEST = "LOGIN_FOR_ADMIN_REQUEST";
export const LOGIN_FOR_ADMIN_SUCCESS = "LOGIN_FOR_ADMIN_SUCCESS";
export const LOGIN_FOR_ADMIN_FAILURE = "LOGIN_FOR_ADMIN_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const login = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_FOR_ADMIN_REQUEST });
      const url = `${BASE_URL}/auth/admin/login/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: LOGIN_FOR_ADMIN_SUCCESS, payload: response.data });
      navigate("/dashboard");
      toast.success("Login Successfull");
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FOR_ADMIN_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};


export const resetPasswordForAdmin = (data,navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });
      const url = `${BASE_URL}/auth/reset/password/for/admin/v1`;
      const response = await axios.put(
        url,
        data,
      );
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      toast.success("Password Reset Successfully. Please Login to continue.")
      navigate("/login")
    } catch (error) {
      console.error(error);
      dispatch({ type: RESET_PASSWORD_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};