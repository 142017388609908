import * as loginAction from "./loginAction";

export const loginFeatureKey = "loginInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  userDetails: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case loginAction.LOGIN_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginAction.LOGIN_FOR_ADMIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        userDetails: payload.data,
      };
    case loginAction.LOGIN_FOR_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case loginAction.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginAction.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case loginAction.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
