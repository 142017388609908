import * as planAction from "./planAction";

export const planFeatureKey = "planInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  plans: [],
  planDetails: {},
  isPlanUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case planAction.GET_PLAN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.GET_PLAN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: payload.data,
      };
    case planAction.GET_PLAN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        plans: [],
      };
    case planAction.GET_PLAN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.GET_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        planDetails: payload.data,
      };
    case planAction.GET_PLAN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case planAction.CREATE_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.CREATE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        isPlanUpdated: true,
      };
    case planAction.CREATE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case planAction.UPDATE_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        isPlanUpdated: true,
      };
    case planAction.UPDATE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case planAction.DELETE_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        isPlanUpdated: true,
      };
    case planAction.DELETE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_PLAN_UPDATED":
      return {
        ...state,
        isPlanUpdated: false,
      };
    default:
      return state;
  }
};
