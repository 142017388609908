import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as courseAction from "../../../../redux/courses/courseAction";

const BASE_AWS_URL = process.env.REACT_APP_AWS_URL;

const Courses = () => {
  const { courseDetails } = useSelector((state) => state.courseInfo);
  const [formData, setFormData] = useState({
    tutorName: "",
    courseName: "",
    givingLessonFor: "",
    shortDescription: "",
    longDescription: "",
    duration: "",
    date: "",
    image: null,
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(courseAction.getCourseDetails(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (courseDetails && id) {
      setFormData({
        courseName: courseDetails.courseName || "",
        shortDescription: courseDetails.shortDescription || "",
        longDescription: courseDetails.longDescription || "",
        tutorName: courseDetails.tutorName || "",
        givingLessonFor: courseDetails.givingLessonFor || "",
        duration: courseDetails.duration || "",
        date: courseDetails.date || "",
        image: courseDetails.image || null,
      });
    }
  }, [courseDetails, id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      tutorName,
      courseName,
      shortDescription,
      longDescription,
      givingLessonFor,
    } = formData;

    if (
      !tutorName ||
      !courseName ||
      !shortDescription ||
      !longDescription ||
      !givingLessonFor
    ) {
      return toast.error("All fields are required");
    }

    dispatch(courseAction.createCourse(formData, formData.image, navigate));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const {
      tutorName,
      courseName,
      shortDescription,
      longDescription,
      givingLessonFor,
    } = formData;

    if (
      !tutorName ||
      !courseName ||
      !shortDescription ||
      !longDescription ||
      !givingLessonFor
    ) {
      return toast.error("All fields are required");
    }

    dispatch(courseAction.updateCourse(id, formData, formData.image, navigate));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevForm) => ({
        ...prevForm,
        image: file,
      }));
    }
  };

  const handleCancel = () => {
    navigate("/courses/list");
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">{id ? "Update Course" : "Create Course"}</h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {id ? "Update Details" : "Enter New Course Details"}
              </h4>

              <form className="form-sample">
                <div className="form-group">
                  <label>Course Name</label>
                  <input
                    value={formData.courseName}
                    onChange={handleChange}
                    placeholder="Course Name"
                    name="courseName"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Tutor Name</label>
                  <input
                    value={formData.tutorName}
                    onChange={handleChange}
                    placeholder="Enter Tutor Name"
                    name="tutorName"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Give Lesson For</label>
                  <input
                    value={formData.givingLessonFor}
                    onChange={handleChange}
                    placeholder="Give Lesson For"
                    name="givingLessonFor"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Short Description</label>
                  <textarea
                    value={formData.shortDescription}
                    onChange={handleChange}
                    placeholder="Short Description"
                    name="shortDescription"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Long Description</label>
                  <textarea
                    value={formData.longDescription}
                    onChange={handleChange}
                    placeholder="Long Description"
                    name="longDescription"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Duration</label>
                  <input
                    value={formData.duration}
                    onChange={handleChange}
                    placeholder="Duration"
                    name="duration"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Date</label>
                  <input
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                    placeholder="Date"
                    name="date"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Course Image</label>
                  <input
                    onChange={handleImageChange}
                    name="image"
                    type="file"
                    accept="image/*"
                    className="form-control"
                  />
                  {formData.image && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={
                          typeof formData.image === "string"
                            ? `${BASE_AWS_URL}${formData.image}`
                            : URL.createObjectURL(formData.image)
                        }
                        alt="Course Preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="form-buttons">
                  {id ? (
                    <button
                      type="submit"
                      onClick={handleUpdate}
                      className="btn btn-info mr-2"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary mr-2"
                    >
                      Submit
                    </button>
                  )}
                  <button className="btn btn-light" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
