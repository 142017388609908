import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const GET_ADMIN_LIST_REQUEST = "GET_ADMIN_LIST_REQUEST";
export const GET_ADMIN_LIST_SUCCESS = "GET_ADMIN_LIST_SUCCESS";
export const GET_ADMIN_LIST_FAILURE = "GET_ADMIN_LIST_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const MAKE_USER_INACTIVE_REQUEST = "MAKE_USER_INACTIVE_REQUEST";
export const MAKE_USER_INACTIVE_SUCCESS = "MAKE_USER_INACTIVE_SUCCESS";
export const MAKE_USER_INACTIVE_FAILURE = "MAKE_USER_INACTIVE_FAILURE";

export const CREATE_SUB_ADMIN_REQUEST = "CREATE_SUB_ADMIN_REQUEST";
export const CREATE_SUB_ADMIN_SUCCESS = "CREATE_SUB_ADMIN_SUCCESS";
export const CREATE_SUB_ADMIN_FAILURE = "CREATE_SUB_ADMIN_FAILURE";

export const UPDATE_SUB_ADMIN_REQUEST = "UPDATE_SUB_ADMIN_REQUEST";
export const UPDATE_SUB_ADMIN_SUCCESS = "UPDATE_SUB_ADMIN_SUCCESS";
export const UPDATE_SUB_ADMIN_FAILURE = "UPDATE_SUB_ADMIN_FAILURE";

export const MAKE_USER_INPREMIUM_REQUEST = "MAKE_USER_INPREMIUM_REQUEST";
export const MAKE_USER_INPREMIUM_SUCCESS = "MAKE_USER_INPREMIUM_SUCCESS";
export const MAKE_USER_INPREMIUM_FAILURE = "MAKE_USER_INPREMIUM_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const getUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_REQUEST });
      const url = `${BASE_URL}/user/get/admin/details/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_USER_FAILURE, payload: error.message });
    }
  };
};

export const getUserList = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_LIST_REQUEST });
      const url = `${BASE_URL}/user/admin/get/all/users/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_USER_LIST_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getAdminList = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ADMIN_LIST_REQUEST });
      const url = `${BASE_URL}/user/admin/get/all/subAdmin/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ADMIN_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_ADMIN_LIST_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getUserDetailsId = (userId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_DETAILS_REQUEST });
      const url = `${BASE_URL}/user/admin/get/user/${userId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_USER_DETAILS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const makeUserInactive = (userId, statusUpdate) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: "IS_USER_UPDATE" });
      dispatch({ type: MAKE_USER_INACTIVE_REQUEST });
      const url = `${BASE_URL}/user/admin/inactivate/user/${userId}/v1`;
      const response = await axios.put(
        url,
        { statusUpdate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: MAKE_USER_INACTIVE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAKE_USER_INACTIVE_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const makeUserInPremium = (userId, isSubscribedUser) => {
  return async (dispatch) => {
    try {
      dispatch({ type: MAKE_USER_INPREMIUM_REQUEST });
      const url = `${BASE_URL}/user/admin/insubscribe/user/${userId}/v1`;
      const response = await axios.put(
        url,
        { isSubscribedUser },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: MAKE_USER_INPREMIUM_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: MAKE_USER_INPREMIUM_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};


export const changePasswordForAdmin = (data,navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });
      const url = `${BASE_URL}/auth/change/password/for/admin/v1`;
      const response = await axios.put(
        url,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
      toast.success("Password Changed Successfully. Please Login to continue.")
      localStorage.removeItem("token")
      navigate("/login")
    } catch (error) {
      console.error(error);
      dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const createSubAdmin = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_SUB_ADMIN_REQUEST });
      const url = `${BASE_URL}/user/admin/add/user/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Successfully created");
      dispatch({ type: CREATE_SUB_ADMIN_SUCCESS, payload: response.data });
      navigate('/subAdmin/list');
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_SUB_ADMIN_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateSubAdmin = (_id, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUB_ADMIN_REQUEST });
      const url = `${BASE_URL}/user/admin/update/user/${_id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_SUB_ADMIN_SUCCESS, payload: response.data });
      toast.success("Admin Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/subAdmin/list');
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_SUB_ADMIN_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
