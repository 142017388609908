import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_UNIVERSITY_YEAR_DETAILS_REQUEST = "GET_UNIVERSITY_YEAR_DETAILS_REQUEST";
export const GET_UNIVERSITY_YEAR_DETAILS_SUCCESS = "GET_UNIVERSITY_YEAR_DETAILS_SUCCESS";
export const GET_UNIVERSITY_YEAR_DETAILS_FAILURE = "GET_UNIVERSITY_YEAR_DETAILS_FAILURE";

export const GET_UNIVERSITY_YEAR_LIST_REQUEST = "GET_UNIVERSITY_YEAR_LIST_REQUEST";
export const GET_UNIVERSITY_YEAR_LIST_SUCCESS = "GET_UNIVERSITY_YEAR_LIST_SUCCESS";
export const GET_UNIVERSITY_YEAR_LIST_FAILURE = "GET_UNIVERSITY_YEAR_LIST_FAILURE";

export const GET_UNIVERSITY_YEARS_LIST_REQUEST = "GET_UNIVERSITY_YEARS_LIST_REQUEST";
export const GET_UNIVERSITY_YEARS_LIST_SUCCESS = "GET_UNIVERSITY_YEARS_LIST_SUCCESS";
export const GET_UNIVERSITY_YEARS_LIST_FAILURE = "GET_UNIVERSITY_YEARS_LIST_FAILURE";

export const CREATE_UNIVERSITY_YEAR_REQUEST = "CREATE_UNIVERSITY_YEAR_REQUEST";
export const CREATE_UNIVERSITY_YEAR_SUCCESS = "CREATE_UNIVERSITY_YEAR_SUCCESS";
export const CREATE_UNIVERSITY_YEAR_FAILURE = "CREATE_UNIVERSITY_YEAR_FAILURE";

export const UPDATE_UNIVERSITY_YEAR_REQUEST = "UPDATE_UNIVERSITY_YEAR_REQUEST";
export const UPDATE_UNIVERSITY_YEAR_SUCCESS = "UPDATE_UNIVERSITY_YEAR_SUCCESS";
export const UPDATE_UNIVERSITY_YEAR_FAILURE = "UPDATE_UNIVERSITY_YEAR_FAILURE";

export const DELETE_UNIVERSITY_YEAR_REQUEST = "DELETE_UNIVERSITY_YEAR_REQUEST";
export const DELETE_UNIVERSITY_YEAR_SUCCESS = "DELETE_UNIVERSITY_YEAR_SUCCESS";
export const DELETE_UNIVERSITY_YEAR_FAILURE = "DELETE_UNIVERSITY_YEAR_FAILURE";

export const getYears = (searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_YEAR_LIST_REQUEST });
      const url = `${BASE_URL}/universityYear/admin/list/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: GET_UNIVERSITY_YEAR_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_UNIVERSITY_YEAR_LIST_FAILURE, payload: error.message });
    }
  };
};

export const getYearsDetails = (universityYearId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_YEAR_DETAILS_REQUEST });
      const url = `${BASE_URL}/universityYear/admin/${universityYearId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_UNIVERSITY_YEAR_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_UNIVERSITY_YEAR_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const getYearsDetailsByUniversity = (universityId, degreeId, searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_YEARS_LIST_REQUEST });
      const url = `${BASE_URL}/universityYear/admin/list/university/${universityId}/degree/${degreeId}/v1?searchQuery=${searchQuery && searchQuery !== undefined ? searchQuery : ''}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_UNIVERSITY_YEARS_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_UNIVERSITY_YEARS_LIST_FAILURE, payload: error.message });
    }
  };
};

export const createYear = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_UNIVERSITY_YEAR_REQUEST });
      const url = `${BASE_URL}/universityYear/admin/create/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_UNIVERSITY_YEAR_REQUEST, payload: response.data });
      toast.success("Year Created Successfully");
      navigate("/year/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_UNIVERSITY_YEAR_REQUEST, payload: error.message });
    }
  };
};

export const updateYear = (universityId, data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_UNIVERSITY_YEAR_REQUEST });
      const url = `${BASE_URL}/universityYear/admin/update/${universityId}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_UNIVERSITY_YEAR_SUCCESS, payload: response.data });
      toast.success("Year Updated Successfully");
      navigate("/year/list");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_UNIVERSITY_YEAR_FAILURE, payload: error.message });
    }
  };
};

export const deleteYear = (universityId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_YEAR_UPDATED" });
      dispatch({ type: DELETE_UNIVERSITY_YEAR_REQUEST });
      const url = `${BASE_URL}/universityYear/admin/delete/${universityId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_UNIVERSITY_YEAR_SUCCESS, payload: response.data });
      toast.success("Year Deleted Successfully");
      try {
        dispatch({ type: GET_UNIVERSITY_YEAR_LIST_REQUEST });
        const url = `${BASE_URL}/universityYear/admin/list/v1`;
        const response = await axios.get(url, {
          headers: {
            Authorization: localStorage.getItem("token"),
          }
        });
        dispatch({ type: GET_UNIVERSITY_YEAR_LIST_SUCCESS, payload: response.data });
      } catch (error) {
        console.error(error);
        dispatch({ type: GET_UNIVERSITY_YEAR_LIST_FAILURE, payload: error.message });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_UNIVERSITY_YEAR_FAILURE, payload: error.message });
    }
  };
};
