import * as blogAction from "./blogAction";

export const blogFeatureKey = "blogInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  blogs: [],
  blogDetails: {},
  isBlogUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case blogAction.GET_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogAction.GET_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogs: payload.data,
      };
    case blogAction.GET_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case blogAction.GET_BLOGS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogAction.GET_BLOGS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogDetails: payload.data?.[0],
      };
    case blogAction.GET_BLOGS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    // create
    case blogAction.CREATE_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogAction.CREATE_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogAction.CREATE_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case blogAction.UPDATE_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogAction.UPDATE_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogAction.UPDATE_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case blogAction.DELETE_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case blogAction.DELETE_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        isBlogUpdated: true,
      };
    case blogAction.DELETE_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_BLOG_UPDATED":
      return {
        ...state,
        isBlogUpdated: false,
      };
    default:
      return state;
  }
};
